import React from 'react';
import {
    LatLng,
    TKGeocodingOptions,
    TKPeliasGeocoder,
    TKRegionResults,
    TKRoot,
    TKStateUrl,
    TKUIConfig,
    TKUIMapViewProps,
    TKUITripPlanner,
    TKUtil,
    Environment,
    Env,
    overrideClass,
    TKUIConstants,
    TKDefaultGeocoderNames,
    TKGeolocationData,
    DeviceUtil,
    TKRegionsData,
    TripGoApi
} from 'tripkit-react';
import { TK_FOCUS_TARGET_CLASS } from 'tripkit-react/dist/css/GenStyle.css';
import { TimePreference } from 'tripkit-react/dist/model/RoutingQuery';
import OptionsData from 'tripkit-react/dist/data/OptionsData';
import UITransportOptionsAndPriorities from '../../../light_label/src/widget/UITransportOptionsAndPriorities';
import { ReactComponent as Logo } from '../images/logo-tfgm.svg';
import { ReactComponent as LogoAndName } from '../images/logoAndName-tfgm.svg';
import TKTransportOptions from 'tripkit-react/dist/model/options/TKTransportOptions';
import { important } from 'tripkit-react/dist/jss/TKUITheme';
import TKWeightingPreferences from 'tripkit-react/dist/model/options/TKWeightingPreferences';
import TransportUtil from 'tripkit-react/dist/trip/TransportUtil';
import TGUIUserPriorities from '../../../../options/TGUIUserPriorities';
import Constants from 'tripkit-react/dist/util/Constants';

interface IProps {
    apiKey: string
}

// Force production to enable planned trips tracking.
Environment.setEnvironment(Env.PRODUCTION);

// Get current profile instead creation a new one to avoid resetting the other options, e.g. isDarkMode and trackTripSelections;
const defaultUserProfile = OptionsData.instance.get();
defaultUserProfile.weightingPrefs = TKWeightingPreferences.create();
defaultUserProfile.transportOptions = new TKTransportOptions();

const regionsFallback = require("./regionsFallback.json");

function hasAnalyticsConsent() {
    const cookiesMap = TKUtil.getCookiesMap();
    return !!cookiesMap["tfgmConsent"] && cookiesMap["tfgmConsent"].split("|").indexOf("analytics") !== -1;
}

TKUIConstants.RESOURCES_URL = "https://resources.tripgo.com";

const TripPlannerApp: React.FunctionComponent<IProps> = (props: IProps) => {
    const hostname = (window as any).location.hostname;
    const isBeta = hostname.includes("-beta") || (window as any).location.hostname.includes("beta.") || Constants.DEPLOY_URL.includes("beta-");
    /**
     * Start with map on (initViewport) default city (the first one of hardcoded region) until wait for user location.
     * When user location resolves, if it lays inside region bounds, then focus map on it, otherwise do nothing.
     */
    const defaultRegions: TKRegionResults = TKUtil.deserialize(regionsFallback, TKRegionResults);
    const userLocationPromise = ((window as any).tKUserLocationPromise ?
        (window as any).tKUserLocationPromise.then((userCoords: [number, number]) => LatLng.createLatLng(userCoords[0], userCoords[1]))
        :   // Next line is in case this code is executed before geolocate.js is loaded (race condition)
        TKGeolocationData.instance.requestCurrentLocation(true, true).then(userPosition => userPosition.latLng))
        // .then(TKNetworkUtil.delayPromise(4000))
        .then((userLatLng: LatLng) => {
            for (const region of defaultRegions.regions) {
                if (region.contains(userLatLng)) {
                    return userLatLng;
                }
            }
            throw "User location outside bounds."
        });
    const defaultRegion = defaultRegions.regions.find(region => region.name === "UK_GM_Manchester");
    const defaultCityLatLng = defaultRegion!.cities[0];

    // WARN: this is duplicated in config-c923000febe2292c458b4a1ad8ef736b.js
    const geocodeEarthAmendments = {
        remove: [
            { label: "Chadderton", id: "whosonfirst:neighbourhood:1360798573" },
            { label: "Heywood" },
            { label: "Kearsley", id: "openstreetmap:venue:node/21668335" },
            { label: "Appley Bridge", id: "openstreetmap:venue:node/3713687531" },
            { label: "Cheadle Hulme", id: "openstreetmap:venue:node/446424282" },
            { label: "Walkden", id: "openstreetmap:venue:node/6581364398" },
            { label: "Irlam", id: "openstreetmap:venue:node/6414583147" },
            { label: "Hazel Grove", id: "whosonfirst:neighbourhood:1360791217" },
            { label: "Failsworth", id: "openstreetmap:venue:node/30877311" },
            // { label: "OL10 4LY", id: "whosonfirst:postalcode:471278957" },  // OL10 4LY, Rochdale, England, United Kingdom
        ],
        update: [
            { label: "Manchester", lat: 53.4794892, lng: -2.2451148 },
            { label: "Bolton", lat: 53.5782863, lng: -2.4300367 },
            { label: "Rochdale", lat: 53.6136, lng: -2.161 },
            { label: "Stockport", lat: 53.4083, lng: -2.1494 },
            { label: "Salford", lat: 53.4877463, lng: -2.2891921 },
            { label: "Wigan", lat: 53.5448, lng: -2.6318 },
            { label: "Bury", lat: 53.5927543, lng: -2.2972827 },
            { label: "Altrincham", lat: 53.3839662, lng: -2.3525463 },
            { label: "Swinton", lat: 53.5095363, lng: -2.3417975 },
            { label: "Cheadle Hulme", lat: 53.3709830, lng: -2.1928018 },
            { label: "Walkden", lat: 53.5242276, lng: -2.3984426 },
            { label: "Irlam", lat: 53.4488075, lng: -2.4223799 },
            { label: "Hazel Grove", lat: 53.3768486, lng: -2.1178170 },
            { label: "Failsworth", lat: 53.5149749, lng: -2.1500325 },
            { label: "Shaw", lat: 53.5818981, lng: -2.1039554 },
            { label: "Kearsley", lat: 53.5398993, lng: -2.3781108 },
            { label: "Appley Bridge", lat: 53.5840403, lng: -2.7237811 },
            { label: "Hattersley", lat: 53.4508345, lng: -2.0277746 },
        ],
        add: [
            { address: "Chadderton, England, United Kingdom", lat: 53.54989997521833, lng: -2.146808061648644 },
            { address: "Heywood, Rochdale, England, United Kingdom", lat: 53.59, lng: -2.219 },
            { address: "Standish, Wigan, England, United Kingdom", lat: 53.5863262, lng: -2.6635746 },
            { address: "Uppermill, Oldham, England, United Kingdom", lat: 53.5475135, lng: -2.0066494 },
            { address: "Lees, Oldham, England, United Kingdom", lat: 53.5382578, lng: -2.0733461 },
            { address: "Worsley, Manchester, England, United Kingdom", lat: 53.4999331, lng: -2.3783778 },
            { address: "Aspull, Wigan, England, United Kingdom", lat: 53.5686712, lng: -2.5862312 },
            { address: "High Lane, Stockport, England, United Kingdom", lat: 53.3651108, lng: -2.0696568 },
            // {
            //     address: "Market Street, Heywood, England, United Kingdom",
            //     lat: 53.593845,
            //     lng: -2.222508,
            //     match: ({ query, address }) => query.match(/^OL10\s?4LY\s*$/) ||
            //         (query.length > 3 && LocationUtil.standarizeForMatch(address).startsWith(LocationUtil.standarizeForMatch(query)))
            // }
        ]
    };

    const geocodeEarth = new TKPeliasGeocoder({
        server: "https://api.geocode.earth/v1",
        apiKey: "ge-2c520e919e160e8a",
        restrictToBounds: true,
        resultsLimit: 5,
        sources: "-gn",  // Remove Geonames source
        ammendments: geocodeEarthAmendments
    });

    const tripCompareFc = (t1, t2) => {
        const modePriorities = [["pt_pub", "cy_bic", "cy_bic-s", "wa_wal", "wa_whe"]];
        const bucketT1 = TransportUtil.matchingBucketIndex(t1, modePriorities);
        const bucketT2 = TransportUtil.matchingBucketIndex(t2, modePriorities);
        const adjustedWeight = t => (t.hasPublicTransport() && t.getSegments().some(segment => segment.isCar() || segment.isBicycle())) ?
            t.weightedScore * 1.2 : t.weightedScore;
        return bucketT1 === bucketT2 ? adjustedWeight(t1) - adjustedWeight(t2) : bucketT1 - bucketT2;
    }

    const config: TKUIConfig = {
        apiKey: props.apiKey,
        apiServer: isBeta ? TripGoApi.SATAPP_BETA : undefined,
        theme: {
            colorPrimary: '#40403E'
        },
        isDarkMode: false,
        analytics: {
            google: {
                tracker: {
                    trackingId: "G-BNR06JQMT6"
                },
                isEnabled: hasAnalyticsConsent
            }
        },
        initViewport: { center: defaultCityLatLng, zoom: 13 },
        fixToInitViewportRegion: true,
        geocoding: (defaultOptions: TKGeocodingOptions) => {
            const geocoders = { ...defaultOptions.geocoders };
            delete geocoders[TKDefaultGeocoderNames.recent];
            delete geocoders[TKDefaultGeocoderNames.cities];
            geocoders[TKDefaultGeocoderNames.skedgo].getOptions().resultsLimit = undefined; // No limit to Skedgo geocoder results, though the endpoint has a limit of 20.
            return ({
                geocoders: {
                    ...geocoders,
                    'geocodeEarth': geocodeEarth
                },
                restrictToCoverageBounds: true,
                getFocus: (({ mapViewport }) => {
                    const closerRegion = mapViewport?.center && TKRegionsData.instance.getCloserRegion(mapViewport.center);
                    return closerRegion ? (closerRegion.cities.length !== 0 ? closerRegion.cities[0] : closerRegion.bounds.getCenter()) : mapViewport?.center;
                }),
                maxResults: DeviceUtil.isPhone ? 5 : // To avoid scroll, and avoid list to hide map (portrait view).
                    (DeviceUtil.isTouch() ? 9 : // To avoid scroll on tablets, specially given it's touch (9 results fit in menuMaxHeightPx = 400 set below).
                        15),
                filter: (l, { query }) => {
                    if (l.source === TKDefaultGeocoderNames.skedgo && l.address?.includes("(Manchester Metrolink) (To ")) {
                        const toPlatform = l.address!.toLowerCase().match(/\(to (.*)\)/)?.[1];
                        if (toPlatform && query.toLowerCase().includes(toPlatform) && !query.toLowerCase().includes("to " + toPlatform)) {
                            return false;   // Filter out if query includes the direction indicator name IndName, but does not explicitly include "to " + IndName.
                        }
                    }
                    if (l.source === TKDefaultGeocoderNames.skedgo && l.address?.includes("(Manchester Metrolink) (From ")) {
                        const fromPlatform = l.address!.toLowerCase().match(/\(from (.*)\)/)?.[1];
                        if (fromPlatform && query.toLowerCase().includes(fromPlatform) && !query.toLowerCase().includes("from " + fromPlatform)) {
                            return false;
                        }
                    }
                    return true;
                }
            });
        },
        defaultUserProfile,
        resetUserProfile: true,
        i18n: {
            locale: 'en',
            translations: {
                "Leave": "Leave after",
                "Arrive": "Arrive before",
                "Reset": "Reset priorities"
            }
        },
        tripCompareFc: tripCompareFc,
        booking: {
            enabled: () => false,
        },
        TKUIMapView: {
            props: (props: TKUIMapViewProps) => {
                const { trip, tripSegment } = props;
                const mapTiles = tripSegment ? tripSegment.mapTiles : trip?.mainSegment?.mapTiles;
                return (mapTiles ?
                    {
                        tileLayerProps: {
                            attribution: mapTiles.sources
                                .map(source => `<a href=${source.provider.website} tabindex='-1'>${source.provider.name}</a>`)
                                .join(" | "),
                            url: mapTiles.urlTemplates[0]
                        }
                    } :
                    {
                        mapboxGlLayerProps: {
                            accessToken: "pk.eyJ1IjoibWdvbWV6bHVjZXJvIiwiYSI6ImNsbXFzbDUzMzAwcDAya3Q0bGVvd3picTcifQ.NGWQW1maEHmoMfYAdTyBWw",
                            style: props.theme.isLight ?
                                "mapbox://styles/mgomezlucero/ckjliu0460xxh1aqgzzb2bb34" :
                                "mapbox://styles/mgomezlucero/ckbmm6m0w003e1hmy0ksjxflm",
                            attribution: "<a href='http://osm.org/copyright' tabindex='-1'>OpenStreetMap</a>"
                        }
                    }
                );
            }
        },
        TKUITripPlanner: {
            props: (props) => ({
                renderTopRight: props.landscape ? () => <Logo style={{ height: '50px' }} /> : undefined,
                transportSettingsUI: "FULL"
            }),
            styles: {
                ariaFocusEnabled: {
                    ['& input[type=text]:focus,' +
                        '& input[type=email]:focus,' +
                        '& input[aria-autocomplete=list]:focus,' +
                        '& button:focus,' +
                        '& a:focus,' +
                        '& select:focus,' +
                        '& textarea:focus,' +
                        '& div:focus,' +
                        '&:focus,' +
                        '& .' + TK_FOCUS_TARGET_CLASS + ':focus'
                    ]: {
                        outline: '4px solid #119ec6 !important',
                        transition: 'all .5s'
                    },
                    ['& button[name="continue-btn"]' + ':focus']: {
                        outline: '4px solid #FFDB4E !important',
                    },
                    ['& button[name="transport-options-btn"]' + ':focus']: {
                        outline: '4px solid #FFDB4E !important',
                    }
                }
            }
        },
        TKUISidebar: {
            props: () => ({
                renderLogo: () => <LogoAndName style={{ height: '50px' }} />
            })
        },
        TKUILocationBox: {
            props: {
                menuMaxHeightPx: 400
            }
        },
        TKUITripRow: {
            styles: {
                selectedAlternative: overrideClass({
                    backgroundColor: '#FAFAFA'
                })
            }
        },
        TKUIRoutingQueryInput: {
            props: {
                transportBtnText: 'Transport options',
                sideDropdown: false // Maybe make it the default for TripGo
            },
            styles: theme => ({
                swap: overrideClass({
                    '& path': {
                        fill: theme.colorPrimary + '!important',
                    },
                    '&:hover path': {
                        fill: 'black!important'
                    }
                }),
                footer: overrideClass({
                    backgroundColor: '#FAFAFA',
                    padding: '0px 2px 0 4px'
                }),
                transportsBtn: defaultStyle => ({
                    ...defaultStyle,
                    ...defaultStyle.color && !(defaultStyle.color as string).includes('!important') &&
                    { color: defaultStyle.color + '!important' } // To avoid being overridden on hover / focus
                }),
                timePrefSelect: overrideClass({
                    // props are TKUISelect props, since timePrefSelect class is used to define TKUISelect main class.
                    minWidth: props => props.value.value === TimePreference.ARRIVE ? '108px' : '97px'
                }),
                datePicker: overrideClass({
                    whiteSpace: 'nowrap'
                })
            })
        },
        TKUIRoutingResultsView: {
            props: {
                transportBtnText: 'Transport options'
            },
            styles: theme => ({
                timePrefSelect: overrideClass({
                    // props are TKUISelect props, since timePrefSelect class is used to define TKUISelect main class.
                    minWidth: props => props.value?.value === TimePreference.ARRIVE ? '120px' : '106px',
                    marginRight: '5px'
                }),
                transportsBtn: overrideClass({
                    whiteSpace: 'normal',
                    ...important(theme.textColorGray)
                })
            })
        },
        TKUIDateTimePicker: {
            styles: {
                datePicker: overrideClass({
                    '&:hover,&:focus': {
                        color: '#666d71!important' // To avoid being overridden on hover / focus
                    }
                })
            }
        },
        TKUISelect: {
            styles: {
                option: overrideClass({
                    color: 'black'
                })
            }
        },
        TKUIButton: {
            styles: theme => ({
                primary: overrideClass({
                    '&:hover': {
                        backgroundColor: '#000000'
                    }
                }),
                secondary: overrideClass({
                    color: theme.colorPrimary + '!important',
                    backgroundColor: 'white',
                    '&:hover': {
                        backgroundColor: '#E5E5E5'
                    }
                }),
                link: overrideClass({
                    '&:hover': {
                        color: '#000000!important'
                    },
                    '&:focus': {
                        color: '#000000!important'
                    }
                })
            })
        },
        TKUITransportOptionsView: {
            render: props => <UITransportOptionsAndPriorities {...props} />
        },
        TKUIMapShapes: {
            props: {
                travelledOnly: true
            }
        },
        TKUIUserPriorities: {
            render: props => {
                const { styles, injectedStyles, classes, refreshStyles, theme, ...otherProps } = props;
                return <TGUIUserPriorities {...otherProps} />;
            }
        },
        TKUICard: {
            props: {
                modalOptions: {
                    parentSelector: () => document.getElementsByTagName("BODY")[0]
                }
            }
        }
    };

    return (
        <TKRoot config={config}>
            <TKUITripPlanner userLocationPromise={userLocationPromise}
                styles={{
                    modalMain: overrideClass({
                        position: 'relative',   // This causes some issues on mobile (see comment in TKUITripPlanner.css.ts). Address this.
                        overflowY: 'hidden!important'
                    })
                }}
            />
            <TKStateUrl useHash={true} />
        </TKRoot>
    );
};

export default TripPlannerApp;