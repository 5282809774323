import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "reflect-metadata";
import React from 'react';
import ReactDOM from 'react-dom';
import TripPlannerApp from "./app/TripPlannerApp";

const containerElement = document.getElementById("tripgo-planner") as HTMLElement;
ReactDOM.render(
    <TripPlannerApp apiKey={"c923000febe2292c458b4a1ad8ef736b"}/>, containerElement
);
