import React, { useState } from "react";
import ModeIdentifier from "tripkit-react/dist/model/region/ModeIdentifier";
import TKUICard, { CardPresentation } from "tripkit-react/dist/card/TKUICard";
import RegionsData from "tripkit-react/dist/data/RegionsData";
import TKUITransportOptionsRow from "tripkit-react/dist/options/TKUITransportOptionsRow";
import TKUIUserPriorities from "tripkit-react/dist/options/TKUIUserPriorities";
import TKUISettingSection from "tripkit-react/dist/options/TKUISettingSection";
import TKUserProfile from "tripkit-react/dist/model/options/TKUserProfile";
import TKUtil from "tripkit-react/dist/util/Util";
import { overrideClass } from "tripkit-react/dist/jss/StyleHelper";
// import { ReactComponent as IconSpin } from 'tripkit-react/dist/images/ic-loading2.svg';

const UITransportOptionsAndPriorities = props => {
    const { region, classes, t, onRequestClose, value, onChange, slideUpOptions, landscape, cardContainerClass } = props;
    const [update, setUpdate] = useState<TKUserProfile>(value);
    const regionModes = region ? region.modes.concat([ModeIdentifier.WHEELCHAIR_ID]) : undefined;
    const onRequestCloseFc = () => {
      if (onRequestClose) {
        // Communicate change upwards on close
        update !== value && onChange(update);
        onRequestClose();
      }
    }
    return (
      <TKUICard
        title={t("Transport")}
        presentation={landscape ? CardPresentation.MODAL : CardPresentation.SLIDE_UP}
        focusTrap={true}
        onRequestClose={onRequestCloseFc}
        slideUpOptions={slideUpOptions}
        className={cardContainerClass}
      >
        {!regionModes ?
          <div className={classes.loadingPanel}>
            {/* <IconSpin className={classes.iconLoading} focusable="false" /> */}
          </div>
          :
          <div className={classes.main}>
            {regionModes.map((mode: string, i: number) => {
              const modeIdentifier = RegionsData.instance.getModeIdentifier(mode)!;
              return <TKUITransportOptionsRow
                mode={modeIdentifier}
                value={update}
                onChange={value => setUpdate(value)}
                key={i}
              />
            })}
            <TKUISettingSection title={t("My.Priorities")}>
              <TKUIUserPriorities
                value={update.weightingPrefs}
                onChange={prefsUpdate => setUpdate(TKUtil.iAssign(update, { weightingPrefs: prefsUpdate }))}
                cardPresentation={CardPresentation.CONTENT}
                styles={{
                  main: overrideClass({
                    padding: '30px 0'
                  })
                }}
              />
            </TKUISettingSection>
          </div>
        }
      </TKUICard>
    )
  };

  export default UITransportOptionsAndPriorities;