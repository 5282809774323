import * as React from "react";
import { tGUIUserPrioritiesDefaultStyle } from "./TGUIUserPriorities.css";
import { Subtract } from "utility-types";
import { CSSProps, overrideClass, TKUIWithClasses, TKUIWithStyle } from "tripkit-react/dist/jss/StyleHelper";
import HasCard, { HasCardKeys } from "tripkit-react/dist/card/HasCard";
import TKWeightingPreferences, { WeightingPreference } from "tripkit-react/dist/model/options/TKWeightingPreferences";
import { TKUIViewportUtil, TKUIViewportUtilProps } from "tripkit-react/dist/util/TKUIResponsiveUtil";
import { TKComponentDefaultConfig, TKUIConfig } from "tripkit-react/dist/config/TKUIConfig";
import TKUICard, { CardPresentation } from "tripkit-react/dist/card/TKUICard";
import Constants from "tripkit-react/dist/util/Constants";
import TKUIButton, { TKUIButtonType } from "tripkit-react/dist/buttons/TKUIButton";
import { connect, PropsMapper } from "tripkit-react/dist/config/TKConfigHelper";
import { TKUIConfigContext } from "tripkit-react/dist/config/TKUIConfigProvider";
import TKUISlider from "tripkit-react/dist/options/TKUISlider";

export interface IClientProps extends TKUIWithStyle<IStyle, IProps>, Pick<HasCard, HasCardKeys.onRequestClose | HasCardKeys.cardPresentation | HasCardKeys.slideUpOptions> {
    value: TKWeightingPreferences;
    onChange: (update: TKWeightingPreferences) => void;
}
export interface IConsumedProps extends TKUIViewportUtilProps {
    config: TKUIConfig;
}
export interface IStyle {
    main: CSSProps<IProps>;
}

interface IProps extends IClientProps, IConsumedProps, TKUIWithClasses<IStyle, IProps> { }

export type TGUIUserPrioritiesProps = IProps;
export type TGUIUserPrioritiesStyle = IStyle;

const config: TKComponentDefaultConfig<IProps, IStyle> = {
    render: props => <TGUIUserPriorities {...props} />,
    styles: tGUIUserPrioritiesDefaultStyle,
    classNamePrefix: "TGUIUserPriorities"
};

const slidePrefTo = (prefs: TKWeightingPreferences, pref: WeightingPreference, value: number): TKWeightingPreferences => {
    const result = new TKWeightingPreferences();
    const total = 5;
    const oldRemainder = total - prefs[pref];
    const newRemainder = total - value;
    for (const prefName of Object.keys(WeightingPreference)) {
        if (prefName === pref) {
            result[prefName] = value;
        } else {
            result[prefName] = Math.min((prefs[prefName] * newRemainder) / oldRemainder, 2);
        }
    }
    return result;
}

class TGUIUserPriorities extends React.Component<IProps, {}> {

    public render(): React.ReactNode {
        const { classes, t, value: priorities, cardPresentation, config } = this.props;
        return (
            <TKUICard
                title={t("My.Priorities")}
                presentation={cardPresentation ?? (this.props.landscape ? CardPresentation.MODAL : CardPresentation.SLIDE_UP)}
                focusTrap={true}
                onRequestClose={this.props.onRequestClose}
                slideUpOptions={this.props.slideUpOptions}
            >
                <div className={classes.main}>
                    <TKUISlider
                        // TODO: consider using defaultValue + onChangeCommited, which is more efficient than value + onChange.
                        // Warning: with defaultValue + onChangeCommited slider won't update if priorities modified from
                        // outside.
                        value={priorities.money * 100}
                        onChange={((event: any, value: any) => {
                            this.props.onChange(slidePrefTo(priorities, WeightingPreference.money, value / 100));
                        }) as any}
                        thumbIconUrl={Constants.absUrl("/images/priorities/ic-priority-money.svg")}
                        label={t("Money")}
                        leftLabel={"I don't care"}
                        rightLabel={"Save money"}
                        min={0}
                        max={200}
                        // valueLabelDisplay={'on'}
                        // valueLabelFormat={(value: number) => "Money"}
                        isDarkMode={this.props.theme.isDark}
                        aria-label={t("Money")}
                        theme={this.props.theme}
                    />
                    <TKUISlider
                        value={priorities.time * 100}
                        onChange={((event: any, value: any) =>
                            this.props.onChange(slidePrefTo(priorities, WeightingPreference.time, value / 100))) as any}
                        thumbIconUrl={Constants.absUrl("/images/priorities/ic-priority-time.svg")}
                        label={t("Time")}
                        leftLabel={"I don't care"}
                        rightLabel={"Save time"}
                        min={0}
                        max={200}
                        isDarkMode={this.props.theme.isDark}
                        aria-label={t("Time")}
                        theme={this.props.theme}
                    />
                    <TKUISlider
                        value={priorities.carbon * 100}
                        onChange={((event: any, value: any) =>
                            this.props.onChange(slidePrefTo(priorities, WeightingPreference.carbon, value / 100))) as any}
                        thumbIconUrl={Constants.absUrl("/images/priorities/ic-priority-environment.svg")}
                        label={"Carbon"}
                        leftLabel={"I don't care"}
                        rightLabel={"Reduce carbon"}
                        min={0}
                        max={200}
                        isDarkMode={this.props.theme.isDark}
                        aria-label={"Carbon"}
                        theme={this.props.theme}
                    />
                    <TKUISlider
                        value={priorities.hassle * 100}
                        onChange={((event: any, value: any) =>
                            this.props.onChange(slidePrefTo(priorities, WeightingPreference.hassle, value / 100))) as any}
                        thumbIconUrl={Constants.absUrl("/images/priorities/ic-priority-convenience.svg")}
                        label={t("Convenience")}
                        leftLabel={"I don't care"}
                        rightLabel={"Less hassle"}
                        min={0}
                        max={200}
                        isDarkMode={this.props.theme.isDark}
                        aria-label={t("Convenience")}
                        theme={this.props.theme}
                    />
                    <TKUIButton text={t("Reset")}
                        type={TKUIButtonType.SECONDARY}
                        styles={{
                            main: overrideClass({
                                marginTop: '20px'
                            })
                        }}
                        onClick={() => this.props.onChange(TKWeightingPreferences.create(config.defaultUserProfile?.weightingPrefs))}
                    />
                </div>
            </TKUICard>
        );
    }
}

const Mapper: PropsMapper<IClientProps, Subtract<IProps, TKUIWithClasses<IStyle, IProps>>> =
    ({ inputProps, children }) =>
        <TKUIConfigContext.Consumer>
            {(config: TKUIConfig) =>
                <TKUIViewportUtil>
                    {(viewportProps: TKUIViewportUtilProps) =>
                        children!({ ...inputProps, ...viewportProps, config })}
                </TKUIViewportUtil>}
        </TKUIConfigContext.Consumer>;

export default connect(() => undefined, config, Mapper);