import genStyles from "tripkit-react/dist/css/GenStyle.css";
import { TKUIStyles } from "tripkit-react/dist/jss/StyleHelper";
import { TKUITheme } from "tripkit-react/dist/jss/TKUITheme";
import { TGUIUserPrioritiesProps, TGUIUserPrioritiesStyle } from "./TGUIUserPriorities";


export const tGUIUserPrioritiesDefaultStyle: TKUIStyles<TGUIUserPrioritiesStyle, TGUIUserPrioritiesProps> =
    (theme: TKUITheme) => ({
        main: {
            ...genStyles.flex,
            ...genStyles.column,
            padding: '30px',
            '&>*': {
                marginBottom: '15px'
            },
            color: theme.colorPrimary
        }
    });